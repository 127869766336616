import { useContext, useEffect, useState, useRef, useCallback } from 'react';
import {
  Alert,
  notificationTypes,
} from 'common/context/Notifications/NotificationsContext';
import { NotificationsContext } from 'common/context/Notifications';
import clx from 'classnames';
import styles from './AlertItem.module.css';
import CirleTick from 'common/Icons/CircleTick';
import Warning from 'common/Icons/Warning';
import { IoCloseCircle, IoCloseSharp } from 'react-icons/io5';

const ALERT_AUTOCLOSE_TIME = 3000;
const ALERT_AUTOCLOSE_ANIMATION_TIME = 270;

interface AlertItemProps {
  alert: Alert;
}

const AlertItem = ({ alert }: AlertItemProps) => {
  const { removeAlert } = useContext<notificationTypes>(NotificationsContext);
  const [unmounting, setUnmounting] = useState<boolean>(false);
  const timeoutUnmounting = useRef<NodeJS.Timeout | null>(null);
  const timeoutRemove = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (alert.autoClose) {
      timeoutUnmounting.current = setTimeout(
        () => setUnmounting(true),
        ALERT_AUTOCLOSE_TIME,
      );
      timeoutRemove.current = setTimeout(
        () => removeAlert(alert),
        ALERT_AUTOCLOSE_ANIMATION_TIME + ALERT_AUTOCLOSE_TIME,
      );
    }

    return () => {
      if (timeoutUnmounting.current !== null) {
        clearTimeout(timeoutUnmounting.current);
      }
      if (timeoutRemove.current !== null) {
        clearTimeout(timeoutRemove.current);
      }
    };
  }, [alert, removeAlert]);

  const handleRemove = useCallback(() => {
    setUnmounting(true);
    if (timeoutRemove.current !== null) {
      clearTimeout(timeoutRemove.current);
    }
    timeoutRemove.current = setTimeout(
      () => removeAlert(alert),
      ALERT_AUTOCLOSE_ANIMATION_TIME,
    );
  }, [alert, removeAlert]);

  return (
    <div
      className={clx(
        styles.Alert,
        {
          [styles.Permanent]: !alert.autoClose,
          [styles.Success]: alert.type === 'Success',
          [styles.Error]: alert.type === 'Error',
          [styles.Info]: alert.type === 'Info',
          [styles.Warning]: alert.type === 'Warning',
        },
        unmounting ? styles.Hide : styles.Show,
      )}
      onClick={handleRemove}
    >
      {alert.type === 'Warning' && <Warning />}
      {alert.type === 'Success' && <CirleTick />}
      {alert.type === 'Error' && <IoCloseCircle />}
      <span>{alert.message}</span>
      <a className={styles.close} onClick={handleRemove}>
      <IoCloseSharp />
      </a>
    </div>
  );
};

export default AlertItem;
