import React from 'react';

type Props = {
  children: any;
};

type State = {
  errorMessage: boolean;
  hasError: boolean;
};

export default class ErrorBoundary extends React.Component<
  Props,
  State,
  null | string | React.ReactNode
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      errorMessage: false,
      hasError: false,
    };
  }

  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true };
  }

  componentDidCatch = (error: Error, info: object): void => {
    this.setState({ errorMessage: true });
    console.log(error);
  };

  render = (): null | React.ReactNode => {
    if (this.state.hasError) {
      return this.state.errorMessage
        ? 'Application encountered an error'
        : null;
    }
    return this.props.children;
  };
}
