"use client";

import React, { createContext, FC, useEffect, useState } from 'react';

import { IntlProvider, useIntl } from 'react-intl';
import ar from './ar.json';
import enUS from './en-US.json';
import es from './es.json';

export const DEFAULT_LANGUAGE = 'en';

IntlProvider.displayName = 'IntlProvider';
export const getBrowserLanguage = () => {
  return (
    (typeof localStorage !== 'undefined' && localStorage?.flow_language) ||
    (typeof navigator !== 'undefined' &&
      (navigator.language ||
        // @ts-ignore
        navigator['userLanguage'] ||
        // @ts-ignore
        navigator['browserLanguage'])) ||
    DEFAULT_LANGUAGE
  ).split('-')[0];
};

export const LanguageContext = createContext({} as any);

const getDirection = (language: any) => {
  return language !== 'ar' ? 'ltr' : 'rtl';
};

export const getDirectionality = () => {
  // @ts-ignore
  return getDirection(appLanguage);
};

export const useDirectionality = () => {
  const intl = useIntl();
  return getDirection(intl.locale);
};
// @ts-ignore
let appLanguage;

const DEFAULT_LOCALE = 'en';

const messages: any = {
  [DEFAULT_LOCALE]: enUS,
  es: es,
  ar: ar,
};
type Props = {
  children: any;
};
export const I18nProvider: FC<Props> = ({ children }) => {
  const [language, setLanguage] = useState<string>(DEFAULT_LOCALE);

  useEffect(() => {
    const language = getBrowserLanguage();
    setLanguage(language);
  }, []);
  const dir = getDirection(language);

  const handleSetLanguage = (lang: string) => {
    localStorage.setItem('flow_language', lang);
    setLanguage(lang);
  };
  const configContext = {
    handleSetLanguage,
    language,
  };

  return (
    <LanguageContext.Provider value={configContext}>
      {/* @ts-ignore*/}
      <IntlProvider locale={language} messages={messages[language]}>
        <div className="lang" dir={dir}>
          {React.cloneElement(children)}
        </div>
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

export default I18nProvider;
