import { useIntl } from 'react-intl';

export function useTranslateHook() {
  const intl = useIntl();

  return (contentId: string, contentValues?: any) =>
    intl.formatMessage({ id: contentId }, contentValues);
}

function useTranslate(id: string) {
  const intl = useIntl();

  if (id) {
    return intl.formatMessage({
      id,
    });
  }

  return (contentId: any, contentValues: any) =>
    intl.formatMessage({ id: contentId }, contentValues);
}

export default useTranslate;
