import '../styles/globals.css';
import type { AppProps } from 'next/app';
import { useEffect } from 'react';
import I18nProvider from 'common/i18n/I18nProvider';
import smoothscroll from 'smoothscroll-polyfill';
import { NotificationsContextProvider } from 'common/context/Notifications';
import 'common/custom_css/Calendar.css';
import ErrorBoundary from 'components/ErrorBoundary';
import Alert from 'components/FlowUi/Alert';
import 'cropperjs/dist/cropper.css';
import nProgress from 'nprogress';
import { Router } from 'next/router';
import 'nprogress/nprogress.css';
import 'react-tooltip/dist/react-tooltip.css';
import 'jsondiffpatch/dist/formatters-styles/html.css';

Router.events.on('routeChangeStart', nProgress.start);
Router.events.on('routeChangeError', nProgress.done);
Router.events.on('routeChangeComplete', nProgress.done);

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  return (
    <I18nProvider>
      <NotificationsContextProvider>
        <ErrorBoundary>
          <Alert />
          <Component {...pageProps} />
        </ErrorBoundary>
      </NotificationsContextProvider>
    </I18nProvider>
  );
}

export default MyApp;
