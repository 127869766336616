import React, {
  ReactNode,
  createContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { generateKey } from '@/common/utils/generateKey';
import { useRouter } from 'next/router';
import { useTranslateHook } from '@/common/hooks/useTranslate';

type AlertType = 'Success' | 'Error' | 'Info' | 'Warning';

export type AlertInput = {
  message: string | ReactNode;
  type: AlertType;
  autoClose?: boolean;
  closeOnRouterChange?: boolean;
};

export type Alert = AlertInput & {
  id: string;
};

type Props = {
  children: ReactNode;
};

export interface notificationTypes {
  clearAlerts: () => void;
  alerts: Alert[];
  removeAlert: (alert: Alert) => void;
  addAlert: (alertInput: AlertInput) => Alert;
  addTranslatedAlert: (alertInput: AlertInput) => Alert;
  unsavedChanges: any;
  setUnsavedChanges: any;
}
export const Context = createContext({} as any);

export const Provider = ({ children }: Props) => {
  const [alerts, setAlerts] = useState<Alert[]>([]);
  const router = useRouter();
  const unsavedChanges = useRef(false);

  const setUnsavedChanges = (newValue: boolean) => {
    unsavedChanges.current = true;
  };

  const t = useTranslateHook();

/*   useEffect(() => {
    const warningText = t(
      'You have unsaved changes - are you sure you wish to leave this page?',
    );
    const handleWindowClose = (e: BeforeUnloadEvent) => {
      if (unsavedChanges.current) {
        e.preventDefault();
        return (e.returnValue = warningText);
      } else {
        return;
      }
    };
    const handleBrowseAway = (e: any) => {
      if (!unsavedChanges.current) return;
      if (window.confirm(warningText)) {
        unsavedChanges.current = false;
        return;
      } else {
        router.events.emit('routeChangeError');
        unsavedChanges.current = false;
        throw 'routeChange aborted.';
        return;
      }
    };
    window.addEventListener('beforeunload', handleWindowClose);
    router.events.on('routeChangeStart', handleBrowseAway);
    return () => {
      window.removeEventListener('beforeunload', handleWindowClose);
      router.events.off('routeChangeStart', handleBrowseAway);
    };
  }, [unsavedChanges.current]); */

  const clearAlerts = () => {
    setAlerts([]);
  };

  function removeAlert(alert: Alert) {
    setAlerts((alerts: Alert[]) =>
      alerts.filter((x: Alert) => x.id !== alert.id),
    );
  }

  function addAlert(alertInput: AlertInput): Alert {
    const id = generateKey();
    const alert = {
      ...alertInput,
      autoClose:
        alertInput.autoClose === undefined ? true : alertInput.autoClose,
      id,
    };
    setAlerts((prev: Alert[]) => {
      return [...prev, alert];
    });
    return alert;
  }

  function addTranslatedAlert(alertInput: AlertInput): Alert {
    const id = generateKey();
    const translatedMessage =
      typeof alertInput.message === 'string'
        ? t(alertInput.message)
        : alertInput.message;

    const alert = {
      ...alertInput,
      message: translatedMessage,
      autoClose:
        alertInput.autoClose === undefined ? true : alertInput.autoClose,
      id,
    };
    setAlerts((prev: Alert[]) => {
      return [...prev, alert];
    });
    return alert;
  }

  
  /**
   * On router change
   */
  const handleRouterChange = () => {
    setAlerts((alerts: Alert[]) => {
      return alerts.filter((alert: Alert) => !alert.closeOnRouterChange)
    })
  }

  useEffect(() => {
    router.events.on('beforeHistoryChange', handleRouterChange);
    router.events.on('routeChangeStart', handleRouterChange);

    return () => {
      router.events.off('beforeHistoryChange', handleRouterChange);
      router.events.off('routeChangeStart', handleRouterChange);
    };
  }, [router]);

  const configContext = {
    clearAlerts,
    alerts,
    removeAlert,
    addAlert,
    addTranslatedAlert,
    unsavedChanges,
    setUnsavedChanges,
  };

  return <Context.Provider value={configContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;
