import React from 'react';

const CirleTick = () => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.05894 10.7992C8.73641 10.7992 10.9069 8.65019 10.9069 5.99922C10.9069 3.34825 8.73641 1.19922 6.05894 1.19922C3.38146 1.19922 1.21094 3.34825 1.21094 5.99922C1.21094 8.65019 3.38146 10.7992 6.05894 10.7992ZM8.39601 4.9139C8.54365 4.7129 8.49877 4.43146 8.29576 4.28529C8.09276 4.13911 7.80851 4.18355 7.66087 4.38454L5.54971 7.25864L4.41082 6.13102C4.23332 5.95529 3.94555 5.95529 3.76806 6.13102C3.59056 6.30676 3.59056 6.59168 3.76806 6.76742L5.28306 8.26742C5.37705 8.36048 5.50747 8.40813 5.63999 8.39784C5.7725 8.38755 5.89383 8.32033 5.97201 8.2139L8.39601 4.9139Z"
      fill="#269986"
    />
  </svg>
);

export default CirleTick;
