import { FC } from 'react';

const Warning: FC = () => {
  return (
    <svg
      width="11"
      height="10"
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.88012 0.750416C4.45736 -0.250139 5.90143 -0.250139 6.47868 0.750416L10.1558 7.12417C10.7328 8.12417 10.0111 9.37375 8.85656 9.37375H1.50224C0.347749 9.37375 -0.373965 8.12417 0.202958 7.12417L3.88012 0.750416ZM5.1795 3.37372C5.38661 3.37372 5.5545 3.54161 5.5545 3.74872V5.62372C5.5545 5.83082 5.38661 5.99872 5.1795 5.99872C4.9724 5.99872 4.8045 5.83082 4.8045 5.62372V3.74872C4.8045 3.54161 4.9724 3.37372 5.1795 3.37372ZM5.1795 7.49872C5.38661 7.49872 5.5545 7.33082 5.5545 7.12372C5.5545 6.91661 5.38661 6.74872 5.1795 6.74872C4.9724 6.74872 4.8045 6.91661 4.8045 7.12372C4.8045 7.33082 4.9724 7.49872 5.1795 7.49872Z"
        fill="#D8864B"
      />
    </svg>
  );
};

export default Warning;
