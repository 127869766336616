import { useContext } from 'react';
import {
  type Alert as AlertType,
  notificationTypes,
} from 'common/context/Notifications/NotificationsContext';
import { NotificationsContext } from 'common/context/Notifications';
import AlertItem from './AlertItem';
import styles from './Alert.module.css';

const Alert = () => {
  const { alerts } = useContext<notificationTypes>(NotificationsContext);

  return alerts.length ? (
    <div className={styles.AlertWrapper}>
      {alerts.map((alert: AlertType, index: number) => (
        <AlertItem key={`${alert.id}-${index}`} alert={alert} />
      ))}
    </div>
  ) : null;
};

export default Alert;
